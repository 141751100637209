import React, { useContext } from 'react'
import { useSpring, animated as a } from 'react-spring'

import { BreakpointsContext } from '../context/breakpoints'
import { ContactModalContext } from '../context/ContactModal'

import logoNew from '../assets/svg/logo-new.svg'
import folder from '../assets/svg/folder.svg'
import Link from '../components/Link'
import useInterpConfig from '../hooks/interp'

const Header = ({ spring: { st } }) => {
  const { openModal } = useContext(ContactModalContext)
  const { b } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()

  const headerOpacity = st.to(y => y > 200 ? 0.15 : 1)
  const [headerStyles, headerApi] = useSpring(() => ({
    opacity: headerOpacity,
    translateY: 0,
  }))
  
  const AnimatedLink = a(Link)

  return (
    <a.header
      className="flex items-center fixed top-0 inset-x-0 z-40 px-vw-20 pt-vw-8"
      onPointerOver={() => headerApi.start({ opacity: 1 })}
      onPointerOut={() => headerApi.start({ opacity: headerOpacity })}
      style={headerStyles}
    >
      <a href="/" className="w-vw-32">
        <img
          className="w-vw-24"
          src={logoNew}
          alt="Логотип Keepmoscow"
        />
      </a>

      <div className="flex flex-grow items-center h-vw-7 relative">
        <AnimatedLink href="#approach" text="Наш подход" style={{
          position: 'absolute',
          left: '7.5vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach3'), 0 ],
              [b.get('approach4'), 7 ],
              [b.get('about2', '-40%'), 7 ],
              [b.get('about2', '-30%'), -2 ],
              [b.get('contact', '-60%'), -2 ],
              [b.get('contact', '-20%'), 6 ],
            ]
          )).to(x => `${x}vw`)
        }} />

        <AnimatedLink href="#about" text="Кто мы" style={{
          position: 'absolute',
          left: '21vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach3'), 0 ],
              [b.get('approach4'), 8 ],
              [b.get('approach5'), 8 ],
              [b.get('approach6', '-20%'), 11 ],
              [b.get('about2', '-40%'), 11 ],
              [b.get('about2', '-30%'), 6 ],
              [b.get('team2', '-30%'), 6 ],
              [b.get('team2', '-10%'), 2 ],
              [b.get('contact', '-50%'), 2 ],
              [b.get('contact', '-30%'), 17 ],
            ]
          )).to(x => `${x}vw`)
        }} />

        <AnimatedLink href="#team" text="Команда" style={{
          position: 'absolute',
          left: '37.5vw',
          opacity: st.to(interpConfig(
            [
              [b.get('approach5'), 0 ],
              [b.get('approach6'), 1 ]
            ]
          )),
          visibility: st.to(interpConfig(
            [
              [b.get('approach5'), 0 ],
              [b.get('approach6'), 1 ]
            ]
          )).to(o => o ? 'visible': 'hidden'),
          translateX: st.to(interpConfig(
            [
              [b.get('approach5'), 0 ],
              [b.get('approach6', '-20%'), 12 ],
              [b.get('team1', '-20%'), 12 ],
              [b.get('team2', '-30%'), 12 ],
              [b.get('team2', '-10%'), 4 ],
              [b.get('contact', '-50%'), 4 ],
              [b.get('contact', '-30%'), 16 ],
            ]
          )).to(x => `${x}vw`)
        }} />

        <AnimatedLink href="#contact" text="Контакты" style={{
            position: 'absolute',
            left: '37.5vw',
            zIndex: 1,
            translateX: st.to(interpConfig(
              [
                [b.get('approach2'), 0 ],
                [b.get('approach3'), 3 ],
                [b.get('approach5'), 3 ],
                [b.get('approach6', '-20%'), 28 ],
                [b.get('about2', '-40%'), 28 ],
                [b.get('about2', '-30%'), 31 ],
                [b.get('team2', '-30%'), 31 ],
                [b.get('team2', '-10%'), 26 ],
                [b.get('contact', '-50%'), 26 ],
                [b.get('contact', '-30%'), 31 ],
              ]
            )).to(x => `${x}vw`)
        }} />

        <AnimatedLink text="Скачать каталог" icon={folder} withUnderline={true} onClick={openModal} style={{
          position: 'absolute',
          right: '1.25vw',
          opacity: st.to(interpConfig(
            [
              [b.get('approach5'), 1 ],
              [b.get('approach6', '-20%'), 0 ]
            ]
          )),
          visibility: st.to(interpConfig(
            [
              [b.get('approach5'), 1 ],
              [b.get('approach6', '-20%'), 0 ]
            ]
          )).to(o => o ? 'visible': 'hidden'),
          translateX: st.to(interpConfig(
            [
              [b.get('approach5'), 0 ],
              [b.get('approach6', '-20%'), 20 ]
            ]
          )).to(x => `${x}vw`)
        }} />
      </div>
    </a.header>
  )
}

export default Header
