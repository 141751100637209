import React, { useState, useEffect } from 'react'
import { useTransition, useSprings, animated as a } from 'react-spring'

import Sticker from '../../../../components/Sticker.jsx'

import keepmoscowTeam from '../../../../assets/img/team/webp/mobile/km-team.webp'
import sticker from '../../../../assets/img/team/webp/mobile/sticker-8.webp'
import arrow from '../../../../assets/svg/big-arrow.svg'

const TeamSectionMobile = ({ team }) => {
  const teamEmpty = !team || team.length === 0;
  const [teamOrder, setTeamOrder] = useState(team?.map((_, idx) => idx) ?? [])
  const [teamText, setTeamText] = useState({ name: team?.[0]?.name, description: team?.[0]?.description })

  const [teamSprings, teamSpringApi] = useSprings(
    team?.length || 0,
    idx => ({ x: 0, w: 0, z: 0 })
  )

  const textTransitions = useTransition(teamText, {
    from: { opacity: 0, translateX: 100 },
    enter: { opacity: 1, translateX: 0 },
    leave: { opacity: 0, translateX: -100, position: 'absolute' }
  })

  const handleButtonClick = (dir) => {
    let newOrder = teamOrder

    if (dir === 'next') {
      let fn = newOrder.pop()
      newOrder.unshift(fn)
    } else if (dir === 'prev') {
      let fn = newOrder.shift()
      newOrder.push(fn)
    }

    setTeamOrder(newOrder)
    changeTeamSprings(dir)
  }

  const changeTeamSprings = dir => {
    const idxs = {}

    for (const [idx, midx] of Object.entries(teamOrder)) {
      let nidx = Number(idx)

      if (nidx === 0) {
        idxs[midx] = {
          x: -39,
          z: -4,
          idx: nidx
        }
      } else {
        let invertedIdx = Math.abs((teamOrder.length - 1) - nidx)
        idxs[midx] = {
          x: -invertedIdx * 9,
          z: -invertedIdx,
          idx: nidx
        }
      }
    }

    setTeamText({
      name: team[teamOrder[0]].name,
      description: team[teamOrder[0]].description
    })

    const isBackwards = dir === 'prev' ? true : false
    const isBackwardsLast = (index) => {
      return teamOrder[teamOrder.length - 1] === index && isBackwards
    }
    const getMemberX = (index) => {
      let midx = idxs[index].idx;

      return (
        isBackwardsLast(index)
          ? 10
          : midx > 0 && midx < teamOrder.length - 2
            ? -18
            : idxs[index].x
      )
    }

    teamSpringApi.start(idx => {
      return {
        x: getMemberX(idx),
        w: teamOrder[0] === idx ? 58 : 18,
        z: idxs[idx].z,
        onRest: () => teamSpringApi.start(idx => isBackwardsLast(idx) && {
          x: 0
        })
      }
    })
  }

  useEffect(() => {
    if (!teamEmpty) changeTeamSprings()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section id="team" className="flex flex-col w-full mt-vw-60">
      <h3 className="text-vw-lg ml-vw-32">
        Команда<br />профессиональных<br />брокеров
      </h3>

      {!teamEmpty && (
        <div className="flex flex-col w-full mt-vw-28 overflow-hidden">
          <div className="w-full">
            <div className="flex flex-grow justify-end w-full h-[82vw] relative mb-vw-6">
              {teamSprings.map((styles, idx) => (
                <a.div key={idx} className="h-full absolute top-0 bottom-0" style={{
                  width: styles.w.to(w => `${w}vw`),
                  translateX: styles.x.to(x => `${x}vw`),
                  zIndex: styles.z.to(z => idx === teamOrder[0] || idx === teamOrder[teamOrder.length - 1]
                    ? z : Math.ceil(z)
                  )
                }}>
                  <img
                    className="w-full h-full object-cover object-center"
                    src={team[idx].image.url}
                    alt={team[idx].image.alt}
                  />
                </a.div>
              ))}
            </div>
          </div>

          <div className="flex flex-col w-[86vw] border-r-px border-white relative pl-vw-10 pb-vw-6 pr-vw-32 mt-vw-28">
            <div className="flex justify-between w-full pr-vw-52">
              <button className="flex justify-center w-vw-72" onClick={() => handleButtonClick('prev')}>
                <img
                  className="w-vw-20 filter invert transform rotate-180"
                  src={arrow}
                  alt="стрелка назад"
                />
              </button>

              <button className="flex justify-center w-vw-72" onClick={() => handleButtonClick('next')}>
                <img
                  className="w-vw-20 filter invert"
                  src={arrow}
                  alt="стрелка вперед"
                />
              </button>
            </div>

            <div className="flex mt-vw-40 pl-vw-20">
              {textTransitions((styles, item) => (
                <a.div className="w-full" style={styles}>
                  <h4 className="text text-vw-xl text-orange normal-case mb-vw-2">
                    {item.name}
                  </h4>
                  <p className="text-vw-sm mt-vw-10" style={{ lineHeight: '4.1vw' }}>
                    {item.description}
                  </p>
                </a.div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={`flex flex-col items-stretch w-full relative ${teamEmpty ? 'mt-vw-20' :  'mt-vw-72'} py-vw-32`}>
        <div className="w-1/2 ml-auto pl-vw-28">
          <h4 className="text text-vw-lg normal-case">
            Над каждой<br />
            заявкой<br />
            работает<br />
            <span className="text-orange">целая<br />команда</span>
          </h4>
        </div>

        <img className="w-[calc(100%-2.5vw)] mt-vw-24 mx-vw-4" src={keepmoscowTeam} alt="команда keepmoscow" />

        <div className="w-1/2 pr-vw-24 mt-vw-32">
          <h4 className="text text-vw-lg text-right normal-case">
            Мы рядом с<br />
            вами на всех<br />
            этапах
          </h4>
        </div>

        <div className="flex justify-center w-full mt-vw-28 pl-vw-10">
          <Sticker
            width="38vw"
            sticker={{
              src: sticker,
              alt: 'стикер в секции команда keepmoscow'
            }}
          />
        </div>

        <div className="w-1/2 ml-auto pl-vw-20 mt-vw-14">
          <h4 className="text text-vw-lg normal-case">
            Чтобы вы<br />
            получали<br />только<br />
            <span className="text-orange">удовольствие</span><br />
            от процесса
          </h4>
        </div>

        <div className="w-px h-full absolute top-0 left-1/2 bottom-0 z-[-1] bg-white transform -translate-x-1/2"></div>
      </div>
    </section>
  )
}

export default TeamSectionMobile
