const logoNewPaths = [
  {
    id: 0,
    paths: [
      'M31.7811 15.9087H24.2837V30.1697H31.7811V15.9087Z',
      'M7.49739 0.169556H0V30.1696H7.49739V0.169556Z',
      'M13.2527 19.2998L31.7808 0.169556H21.4484L13.2109 8.74157L13.2548 19.2998H13.2527Z'
    ],
    viewBox: '0 0 35 31',
    width: 35,
    height: 31,
    noMargin: true
  },
  {
    id: 1,
    paths: [
      'M7.20309 6.29865V12.3836H28.8255V17.9555H7.20309V24.0404H28.8255V30.1696H0.251373V0.169556H28.8255V6.29865H7.20309Z'
    ],
    viewBox: '0 0 32 31',
    width: 32,
    height: 31,
    noMargin: true
  },
  {
    id: 2,
    paths: [
      'M7.28799 6.29865V12.3836H28.9104V17.9555H7.28799V24.0404H28.9104V30.1696H0.33844V0.169556H28.9104V6.29865H7.28799Z'
    ],
    viewBox: '0 0 32 31',
    width: 32,
    height: 31,
    noMargin: true
  },
  {
    id: 3,
    paths: [
      'M32.002 11.183C32.002 17.8714 27.7963 22.1565 21.6528 22.1565H7.3673V30.1696H0.417633V0.169556H21.6551C27.794 0.169556 32.0043 4.49881 32.0043 11.183H32.002ZM25.0524 11.183C25.0524 6.63927 21.8099 6.63927 19.723 6.63927H7.3673V15.6847H19.723C21.8077 15.6847 25.0524 15.6847 25.0524 11.1851V11.183Z'
    ],
    viewBox: '0 0 35 31',
    width: 35,
    height: 31,
    noMargin: true
  },
  {
    id: 4,
    paths: [
      'M6.91003 24.4597V30.1696H0.309998V24.4597H6.91003Z'
    ],
    viewBox: '0 0 9 31',
    width: 9,
    height: 31,
    noMargin: true
  }
]

export default logoNewPaths
