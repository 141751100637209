import React, { useContext } from 'react'
import { animated as a } from 'react-spring'

import { BreakpointsContext } from '../../../../context/breakpoints'

import Sticker from '../../../../components/Sticker'

import approach0 from '../../../../assets/img/approach/webp/desktop/approach0.webp'
import approach1 from '../../../../assets/img/approach/webp/desktop/approach1.webp'
import approach2 from '../../../../assets/img/approach/webp/desktop/approach2.webp'
import approach3 from '../../../../assets/img/approach/webp/desktop/approach3.webp'
import approach4 from '../../../../assets/img/approach/webp/desktop/approach4.webp'
import approach6 from '../../../../assets/img/approach/webp/desktop/approach6.webp'
import building2 from '../../../../assets/img/approach/webp/desktop/building-2.webp'
import sticker2 from '../../../../assets/img/approach/webp/desktop/sticker-2.webp'
import sticker3 from '../../../../assets/img/approach/webp/desktop/sticker-3.webp'
import desktop from '../../../../assets/svg/desktop.svg'
import email from '../../../../assets/svg/email-sent.svg'

import useInterpConfig from '../../../../hooks/interp'


const ApproachSection = ({ spring: { st } }) => {
  const { b, setBrRef } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()

  const AnimatedSticker = a(Sticker)

  const interpText = st.to(interpConfig(
    [
      [b.get('approach1'), 10 ],
      [b.get('approach2'), -90 ]
    ]
  )).to(o => `translateY(${ o }vw) rotate(90deg)`)

  const interpImagesY = st.to(interpConfig(
    [
      [b.get('approach4', '40%'), 0 ],
      [b.get('approach5'), -200 ],
    ]
  ))
  const interpImagesOp = st.to(interpConfig(
    [
      [b.get('approach4', '40%'), 1 ],
      [b.get('approach5'), 0 ]
    ]
  ))
  const interpImages2X = st.to(interpConfig(
    [
      [b.get('approach2'), 0 ],
      [b.get('approach3'), -1000 ]
    ]
  ))
  const interpImages2Op = st.to(interpConfig(
    [
      [b.get('approach3', '-1%'), 1 ],
      [b.get('approach3'), 0 ]
    ]
  ))

  return (
    <section id="approach" className="flex w-full mt-vw-20">
      <div className="flex flex-col w-full">
        <div className="flex items-stretch w-full relative px-vw-12 py-vw-7">
          <div className="flex flex-shrink-0 items-center w-[16.25vw] relative">
            <a.h3
              className="text text-[10.6vw] leading-[16vw] uppercase whitespace-nowrap absolute top-vw-20 left-full z-50 origin-top-left"
              style={{ transform: interpText }}
            >
              Наш подход
            </a.h3>
          </div>

          <a.div className="flex flex-shrink-0 items-center w-[28.2vw] h-screen sticky top-0 px-vw-10" style={{
            translateX: st.to(interpConfig(
              [
                [b.get('approach2'), 0 ],
                [b.get('approach3'), -43.5 ],
                [b.get('approach3', '40%'), -43.5 ],
                [b.get('approach4'), -16.2 ]
              ]
            )).to(x => `${x}vw`),
            translateY: st.to(interpConfig(
              [
                [b.get('approach4', '40%'), 0 ],
                [b.get('approach5'), -100 ]
              ]
            ))
          }}>
            <a.p className="text text-vw-4xl leading-[2.2vw] normal-case" style={{
              opacity: st.to(interpConfig(
                [
                  [b.get('approach2'), 1 ],
                  [b.get('approach3'), 0 ]
                ]
              ))
            }}>
              В начале мы<br />проводим<br />интервью<br />и определяем то,<br />что вы хотите<br />
              <span className="text-orange">на самом деле</span>
            </a.p>

            <a.p className="text text-vw-4xl leading-[2.2vw] normal-case absolute inset-x-0 top-1/2 transform -translate-y-1/2 px-vw-10" style={{
              opacity: st.to(interpConfig(
                [
                  [b.get('approach3', '40%'), 0 ],
                  [b.get('approach4', '40%'), 1 ],
                  [b.get('approach5'), 0 ]
                ]
              ))
            }}>
              Оформляем<br />в персональную<br />презентацию
            </a.p>
          </a.div>

          <a.div className="flex flex-col w-[48vw] relative z-10 px-vw-8" style={{
            translateX: st.to(interpConfig(
              [
                [b.get('approach2'), 0 ],
                [b.get('approach3'), -43.5 ],
                [b.get('approach3', '40%'), -43.5 ],
                [b.get('approach4'), -10.5 ]
              ]
            )).to(x => `${x}vw`)
          }}>
            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10"
              src={approach4}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw - 18vh + 4vw)', opacity: interpImages2Op, translateX: interpImages2X }}
            />
            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10 -mt-vw-20"
              src={approach2}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw - 12vh + 4vw)', opacity: interpImagesOp, translateY: interpImagesY }}
            />
            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10 -mt-vw-20"
              src={approach1}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw - 6vh + 4vw)', opacity: interpImages2Op, translateX: interpImages2X }}
            />
            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10 -mt-vw-20"
              src={approach3}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw + 4vw)', opacity: interpImagesOp, translateY: interpImagesY }}
            />

            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10 -mt-vw-20"
              src={approach0}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw + 6vh + 4vw)', opacity: interpImages2Op, translateX: interpImages2X }}
            />
            <a.img
              className="w-full h-[21.4vw] object-cover object-center sticky left-0 right-0 z-10 -mt-vw-20"
              src={approach6}
              alt="фотография объекта в секции наш подход"
              style={{ top: 'calc(50vh - 10.5vw + 12vh + 4vw)', opacity: interpImagesOp, translateY: interpImagesY }}
            />

            <a.div className="flex justify-center items-center h-screen sticky top-[4vw] z-0 inset-0" style={{
              translateY: st.to(interpConfig(
                [
                  [b.get('approach3'), 500 ],
                  [b.get('approach3', '40%'), 500 ],
                  [b.get('approach4'), -6 ]
                ]
              )),
              opacity: st.to(interpConfig(
                [
                  [b.get('approach3'), 0 ],
                  [b.get('approach3', '40%'), 0 ],
                  [b.get('approach4'), 1 ]
                ]
              ))
            }}>
              <img className="max-w-none min-w-[60vw] w-[60vw]" src={desktop} alt="изображение экрана" />
            </a.div>

            <a.div className="flex flex-col justify-center items-center h-screen sticky top-[4vw] z-0 inset-0" style={{
              translateY: st.to(interpConfig(
                [
                  [b.get('approach4'), 10 ],
                  [b.get('approach5'), 0 ]
                ]
              )).to(y => `${y}vw`),
              opacity: st.to(interpConfig(
                [
                  [b.get('approach4'), 0 ],
                  [b.get('approach5'), 1 ]
                ]
              ))
            }}>
              <img className="w-vw-14 mb-vw-10" src={email} alt="изображение письмо отправлено" />
              <p className="text text-vw-4xl text-center normal-case">И отправляем вам</p>
            </a.div>
          </a.div>

          <a.div className="w-[42.75vw] fixed top-1/2 right-0 z-0 pl-vw-4" style={{
            transform: st.to(interpConfig(
              [
                [b.get('approach2'), 831 ],
                [b.get('approach3'), 0 ],
                [b.get('approach3', '40%'), 0 ],
                [b.get('approach4'), 831 ]
              ]
            )).to(offset => `translate(${ offset }px, -50%)`),
            opacity: st.to(interpConfig(
              [
                [b.get('approach2'), 0 ],
                [b.get('approach3'), 1 ],
                [b.get('approach3', '40%'), 1 ],
                [b.get('approach4'), 0 ]
              ]
            ))
          }}>
            <p className="text text-vw-4xl normal-case">
              Подбираем{' '}
              <span className="text-orange">3-5 лучших</span><br />
              вариантов на основе<br />интервью и ваших запросов
            </p>
          </a.div>

          <div className="flex flex-col relative z-50">
            <div ref={ref => setBrRef('approach1', ref)} className="w-px h-screen"></div>
            <div ref={ref => setBrRef('approach2', ref)} className="w-px h-screen"></div>
            <div ref={ref => setBrRef('approach3', ref)} className="w-px h-[140vh]"></div>
            <div ref={ref => setBrRef('approach4', ref)} className="w-px h-screen"></div>
            <div ref={ref => setBrRef('approach5', ref)} className="w-px h-[130vh]"></div>
          </div>
        </div>

        <div ref={ref => setBrRef('approach6', ref)} className="flex flex-col w-full px-vw-12 py-vw-7">
          <div className="flex w-full pt-vw-20">
            <div className="w-[23vw]"></div>
            <p className="text text-vw-4xl normal-case ml-vw-12">
              Для вас это<br />
              <span className="text-orange">бесплатно</span>
            </p>
          </div>

          <div className="flex items-stretch pt-vw-8">
            <div className="flex w-[23vw] h-auto relative">
              <Sticker
                className="absolute top-0 right-0 z-10 transform translate-x-1/2 mt-vw-3"
                width="28.75vw"
                sticker={{
                  src: building2,
                  alt: "стикер здания"
                }}
              />

              <Sticker
                className="absolute top-vw-36 right-0 z-20 transform translate-x-[44%]"
                width="22.3vw"
                sticker={{
                  src: sticker2,
                  alt: "найлейка hello my name is keepmoscow"
                }}
              />
            </div>

            <div className="flex flex-col w-[35vw] relative">
              <AnimatedSticker
                className="absolute top-0 right-0"
                width="19.5vw"
                sticker={{
                  src: sticker3,
                  alt: "стикер диска с лого keepmoscow"
                }}
                style={{
                  translateX: st.to(interpConfig(
                    [
                      [b.get('approach5'), 44.5],
                      [b.get('approach6', '-20%'), 9.75]
                    ]
                  )).to(x => `${x}vw`)
                }}
              />
            </div>

            <div className="flex flex-grow items-center">
              <a.p className="text text-vw-4xl normal-case ml-vw-8 mt-vw-72" style={{
                translateX: st.to(interpConfig([
                  [b.get('approach5'), 30],
                  [b.get('approach6', '-20%'), 0]
                ])).to(x => `${x}vw`),
                opacity: st.to(interpConfig([
                  [b.get('approach5'), 0],
                  [b.get('approach6', '-20%'), 1]
                ]))
              }}>
                Комиссию<br />
                нам платит<br />
                <span className="text-orange">застройщик</span>
              </a.p>
            </div>
          </div>

          <div className="flex w-full pt-56 pl-vw-7">
            <p className="text text-vw-4xl normal-case">
              Подбираем<br />
              недвижимость<br />
              <span className="text-orange">из лучшего</span>,<br />
              что предлагает<br />Московский<br />рынок
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApproachSection
