const Link = ({ href, text, icon, withUnderline, className, onPress, ...props }) => {
  return (
    <a
      {...(href ? { href } : {})}
      className={`group main-link flex items-center cursor-pointer`}
      onClick={onPress ? onPress : null}
      {...props}
    >
      {icon && (
        <div className="sm:w-vw-4 w-vw-16 sm:mr-vw-2 mr-vw-12">
          <img className="w-full" src={icon} alt="скачать каталог" />
        </div>
      )}

      <span className={`xl:text-vw-xl text-vw-base xl:font-light font-light relative ${className ? className : ''}`}>
        {text}

        <div
          className={`h-[0.4vw] xl:h-[0.1vw] bg-orange
              absolute left-0 right-0 xl:bottom-0 -bottom-vw-2
              ${ !withUnderline ? 'opacity-0' : '' }
              group-hover:-bottom-vw-0.5 group-hover:opacity-100
              transition-all`}
        />
      </span>
    </a>
  )
}

export default Link
