import { useState, useEffect, useRef } from 'react'


// thanks to https://stackoverflow.com/a/65628717/10393714
// const useIsVisible = (elementRef) => {
//   return isVisible;
// };

const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: "0px 0px 0px 0px",
  threshold: 0,
};


export default function LazyYoutubeVideo({ videoId, autoplay=false, width }) {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(elementRef.current);
          }
        });
      }, OBSERVER_OPTIONS);

      observer.observe(elementRef.current);
    }
  }, [elementRef]);

  return (
    <div className="aspect-w-16 aspect-h-9 bg-gradient-to-br from-black/60 to-orange/20 backdrop-blur-lg" style={{ width }}>
      <div ref={elementRef} className="w-full h-full">
        {isVisible && (
          <>
            <p className="text text-vw-xl xl:text-vw-2xl text-white text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              Загрузка . . .
            </p>

            <iframe
              className="w-full h-full relative !outline-[none] z-10"
              src={`https://www.youtube.com/embed/${videoId}${autoplay ? '?autoplay=1' : ''}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        )}
      </div>
    </div>
  )
}
