import React from 'react'
import { Link } from 'react-router-dom'

import evgmedLogo from '../assets/svg/evgmed-white.svg'

const FooterLink = ({ routerLink = false, href, text, noLeftPadding, ...props }) => {
  return routerLink ? (
    <Link
      className={`group text-vw-xl font-extralight text-white uppercase
        relative p-vw-3 mx-vw-6 ${noLeftPadding ? 'pl-0 ml-0' : ''}`}
      to={href}
    >
      {text}

      <div
        className={`h-[0.1vw] absolute
          ${noLeftPadding ? 'left-0' : 'left-vw-3'} right-vw-3 bottom-vw-3
          opacity-0 group-hover:opacity-100 group-hover:bottom-vw-2
          transition-all bg-orange`}
      />
    </Link>
  ) : (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={`group text-vw-xl font-extralight text-white uppercase
        relative p-vw-3 mx-vw-6 ${noLeftPadding ? 'pl-0 ml-0' : ''}`}
      {...props}
    >
      {text}

      <div
        className={`h-[0.1vw] absolute
          ${noLeftPadding ? 'left-0' : 'left-vw-3'} right-vw-3 bottom-vw-3
          opacity-0 group-hover:opacity-100 group-hover:bottom-vw-2
          transition-all bg-orange`}
      />
    </a>
  )
}

const Footer = () => {
  return (
    <footer className="flex flex-col relative pt-vw-32 px-vw-16 pb-vw-14">
      <div className="absolute w-full h-full inset-0 z-[-1] bg-gray-dark"></div>

      <div className="flex justify-between items-center relative py-vw-6">
        <div className="flex">
          {/* <FooterLink href="https://www.instagram.com/keep.moscow/" text="INSTAGRAM" noLeftPadding={true} /> */}
          <FooterLink href="mailto:info@keepmoscow.ru" text="info@keepmoscow.ru" />
          <FooterLink routerLink={true} href="/privacy-policy" text="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ" />
        </div>

        <a
          href="https://evgmed.com/-/?utm_source=kpmscw&utm_medium=site&utm_campaign=UTM+keepmoscow"
          className="flex flex-col items-center opacity-50 hover:opacity-100"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-vw-xs font-extralight">CREATED BY</p>
          <img className="w-[5.6vw]" src={evgmedLogo} alt="логотип evgmed" />
        </a>

        <div className="h-px absolute top-0 -left-vw-4 -right-vw-4 bg-white"></div>
        <div className="h-px absolute bottom-0 -left-vw-4 -right-vw-4 bg-white"></div>
      </div>

      <div className="flex justify-center mt-vw-14">
        <p className="text text-vw-lg font-extralight">
          {new Date().getFullYear()} © KEEPMOSCOW
        </p>
      </div>
    </footer>
  )
}

export default Footer
