import React, { useContext } from 'react'
import { animated as a } from 'react-spring'

import { BreakpointsContext } from '../../../../context/breakpoints'

import CreatorCard from '../../../../components/CreatorCard'
import Sticker from '../../../../components/Sticker'

import authors from '../../../../data/authors'

import bothCreators from '../../../../assets/img/about/webp/desktop/maksim-and-pavel.webp'
import helmet from '../../../../assets/img/about/webp/desktop/helmet2.webp'
import building3 from '../../../../assets/img/about/webp/desktop/building-3.webp'
import sticker8 from '../../../../assets/img/about/webp/desktop/sticker-8.webp'
import sticker9 from '../../../../assets/img/about/webp/desktop/sticker-9.webp'

import useInterpConfig from '../../../../hooks/interp'

const AboutUsSection = ({ spring: { st }}) => {
  const { b, setBrRef } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()

  const AnimatedSticker = a(Sticker)

  return (
    <section id="about" className="flex w-full overflow-hidden pt-vw-24 px-vw-12">
      <div className="flex flex-col w-full">
        <div ref={ref => setBrRef('about1', ref)} className="flex w-full mb-vw-28">
          <div className="flex flex-grow-0 flex-shrink-0 w-[16.25vw] relative">
            <a.h3
              className="text text-[10.6vw] leading-[16vw] uppercase whitespace-nowrap absolute bottom-vw-40 left-full z-50 origin-top-left"
              style={{
                transform: st.to(interpConfig(
                  [
                    [b.get('about1', '-50%'), 500 ],
                    [b.get('about1', '50%'), -1350 ]
                  ]
                )).to(o => `translateY(${o}px) rotate(90deg)`)
              }}
            >
              КТО МЫ?
            </a.h3>
          </div>

          <div className="flex flex-col flex-grow">
            <div className="flex justify-center w-full pt-vw-38 mb-vw-20">
              <a.h3 className="text text-vw-4xl text-center normal-case" style={{
                translateX: st.to(interpConfig([
                  [b.get('form'), -13 ],
                  [b.get('about1', '-30%'), 0 ]
                ])).to(x => `${x}vw`)
              }}>
                Мы — свои люди<br/>в недвижимости
              </a.h3>
            </div>

            <div className="flex items-stretch w-full">
              <div className="w-1/2 px-vw-7">
                <CreatorCard {...authors.maksim} />
              </div>

              <div className="w-1/2 px-vw-7">
                <CreatorCard {...authors.pavel} />
              </div>
            </div>
          </div>
        </div>

        <div ref={ref => setBrRef('about2', ref)} className="flex flex-col w-full pb-vw-28">
          <div className="flex h-full">
            <div className="flex flex-col w-[29.8vw] h-full pt-vw-32 px-vw-12">
              <a.p className="text text-vw-4xl text-right normal-case" style={{
                opacity: st.to(interpConfig([
                  [b.get('about2', '-40%'), 0],
                  [b.get('about2', '-10%'), 1],
                ])),
                translateX: st.to(interpConfig([
                  [b.get('about2', '-40%'), -5],
                  [b.get('about2', '-10%'), 0],
                ])).to(x => `${x}vw`)
              }}>
                Работаем <span className="text-orange">со всеми застройщиками</span><br/>в Москве
              </a.p>

              <div className="w-full relative mt-[15vw]">
                <AnimatedSticker
                  width="30vw"
                  sticker={{
                    src: building3,
                    alt: "здание 3"
                  }}
                  style={{
                    left: '7vw',
                    translateX: st.to(interpConfig([
                      [b.get('about2', '-40%'), -3],
                      [b.get('about2', '-10%'), 0],
                    ])).to(x => `${x}vw`)
                  }}
                />
                <AnimatedSticker
                  className="absolute left-vw-40"
                  width="23.3vw"
                  sticker={{
                    src: sticker8,
                    alt: "стикер 4"
                  }}
                  style={{
                    bottom: '4vw',
                    translateX: st.to(interpConfig([
                      [b.get('about2', '-40%'), -3],
                      [b.get('about2', '-10%'), 0],
                    ])).to(x => `${x}vw`)
                  }}
                />
                <AnimatedSticker
                  className="absolute"
                  width="30vw"
                  sticker={{
                    src: sticker9,
                    alt: "стикер warning: do not copy us"
                  }}
                  style={{
                    bottom: '7.5vw',
                    left: '5.6vw',
                    translateX: st.to(interpConfig([
                      [b.get('about2', '-40%'), -3],
                      [b.get('about2', '-10%'), 0],
                    ])).to(x => `${x}vw`),
                    rotate: -19
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-between items-start w-[46.6vw] pb-vw-48 pr-vw-7">
              <a.img className="flex w-[16vw] relative ml-px mt-vw-8" src={helmet} alt="каска keepmoscow" style={{
                translateX: st.to(interpConfig([
                  [b.get('about2', '-40%'), -13.47],
                  [b.get('about2', '-10%'), 0]
                ])).to(x => `${x}vw`)
              }} />

              <p className="text text-vw-4xl text-right normal-case ml-auto">
                Поэтому знаем о<br/>всех стартах продаж<br/>и <span className="text-orange">максимальных<br/>скидках</span>
              </p>
            </div>

            <div className="flex justify-end items-start w-[16.2vw] h-full">
              <img className="max-w-none min-w-[31vw] w-[31vw] mt-vw-48 -mr-vw-7" src={bothCreators} alt="фотография создателей keepmoscow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUsSection
