import React, { useContext, useEffect, useState } from 'react'


import { YT_VIDEO_ID } from '../../../../conf'
import { VideoModalContext } from '../../../../context/VideoModal'
import { ContactModalContext } from '../../../../context/ContactModal'

import Link from '../../../../components/Link'
import Sticker from '../../../../components/Sticker'

// import logoNew from '../../../../assets/svg/logo-new.svg'
import creators from '../../../../assets/img/hero/webp/mobile/creators-new-faded.webp'
import building1 from '../../../../assets/img/hero/webp/mobile/building-1.webp'
import sticker3 from '../../../../assets/img/hero/webp/mobile/sticker-3.webp'
import sticker4 from '../../../../assets/img/hero/webp/mobile/sticker-4.webp'
import folder from '../../../../assets/svg/folder.svg'
import play from '../../../../assets/svg/play-arrow.svg'


const HeroSectionMobile = ({ catalogTitle }) => {
  const { openVideo } = useContext(VideoModalContext)
  const { openModal } = useContext(ContactModalContext)
  const [windowDimensions, setWindowDimensions] = useState({
    w: window.innerWidth,
    h: window.innerHeight
  })

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth !== windowDimensions.w) {
        setWindowDimensions({
          w: window.innerWidth,
          h: window.innerHeight
        })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section id="hero" className="flex flex-col w-full" style={{ height: windowDimensions.h, minHeight: '170vw' }}>
      <div className="flex flex-col w-full h-full">
        <div className="max-w-[80vw] pl-vw-32">
          <div className="border-l-px border-white pt-vw-20 pb-vw-3">
            {/* <img className="w-vw-30 ml-vw-14 mb-vw-10"лк src={logoNew} alt="logo" /> */}

            <h1 className="text-mobile text-vw-xl ml-vw-14" style={{ lineHeight: '6vw', letterSpacing: '-0.1vw' }}>
              Персонализированное агентство по подбору<br/>
              <span className="text-orange">самой крутой</span> недвижимости <span className="text-orange">Москвы</span>
            </h1>
          </div>
        </div>

        <div className="flex flex-col items-end flex-grow w-full relative pt-vw-36">
          <div className="flex flex-col items-start w-[40.6vw] h-full border-l-px border-white px-vw-12 pt-vw-7">
            <h2 className="text-mobile text-vw-base mb-vw-14">{catalogTitle}</h2>

            <Link text="Скачать" icon={folder} withUnderline={true} onPress={openModal} style={{ zIndex: 60 }} />
          </div>

          <div className="w-full absolute bottom-0 px-vw-10">
            <img
              className="w-full relative -bottom-vw-80 z-30 select-none pointer-events-none -mb-vw-8"
              src={creators}
              alt="создатели keepmoscow"
            />

            <Sticker
              className="absolute opacity-70 z-0"
              width="41vw"
              sticker={{
                src: sticker3,
                alt: 'стикер keepmoscow - based in moscow'
              }}
              startXY={[0, -50]}
              style={{
                left: '2.1875vw',
                bottom: '10vw',
                transform: 'rotate(-16deg)'
              }}
            />
            <Sticker
              className="absolute z-10"
              width="15.6vw"
              sticker={{
                src: sticker4,
                alt: 'стикер вся правда о недвижимости'
              }}
              startXY={[25, -50]}
              style={{
                left: '41vw',
                bottom: '15vw',
                transform: 'rotate(9deg)'
              }}
            />
            <Sticker
              className="absolute opacity-90 z-20"
              width="33vw"
              sticker={{
                src: building1,
                alt: 'здание 1'
              }}
              startXY={[0, -50]}
              style={{
                left: '19vw',
                bottom: '34vw',
                transform: 'rotate(-10deg)'
              }}
            />

            <button
              className="flex items-center justify-center w-full absolute left-1/2 bottom-vw-28 z-50 transform -translate-x-1/2 opacity-75 hover:opacity-100 transition-opacity"
              onClick={() => openVideo(YT_VIDEO_ID)}
            >
              <img className="w-vw-20" src={play} alt="иконка видео keep" />

              <p className="text text-[3.4vw] whitespace-nowrap ml-vw-6">
                <span className="text-orange">Хип-хоп</span> на <span className="relative main-underline">рынке недвижимости</span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSectionMobile
